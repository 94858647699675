.App {
  text-align: center;
  font: "Gotham";
  background-color: rgb(204, 167, 137);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e2e2e2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body > #root > div {
  height: 100vh;
}

.Login {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 100px;
}

.HeaderText {
  color: white
}

.ElementsApp input {
  background-color:rgb(185, 185, 199);
}
